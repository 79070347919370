<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Switches from "vue-switches";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { roleService } from "@/app/service/roleService";
import Repository from "@/app/repository/repository-factory";

const PaymentContactRepository = Repository.get("PaymentContactRepository");

/**
 * Edycja kontaktu
 */
export default {
  page: {
    title: "Edycja kontaktu",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Preloader, Switches },
  data() {
    return {
      preloader: false,
      disabledBtn: false,
      header: {
        title: "Edytuj kontakt",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista kontaktów",
            to: { name: "Lista kontaktów" },
          },
          {
            text: "Edycja kontaktu",
          },
        ],
      },
      form: {
        name: "",
        email: "",
        isActive: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(250),
        minLength: minLength(2),
      },
      email: {
        required,
        email,
        maxLength: maxLength(250),
        minLength: minLength(5),
      },
    },
  },
  created() {
    this.getContact();
  },
  methods: {
    async getContact() {
      if (this.$route.params.id) {
        this.preloader = true;
        await PaymentContactRepository.get(this.$route.params.id)
          .then((response) => {
            this.form = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.preloader = false;
    },
    updateContact() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let payload = Object.assign({}, this.form);
      this.disabledBtn = true;
      this.preloader = true;

      PaymentContactRepository.update(payload, this.$route.params.id)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Kontakt został zaktualizowany!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "Lista kontaktów" });
            },
          });
          this.getContact();
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />
    <Preloader v-if="preloader" />

    <b-form @submit.prevent="formSubmit" v-if="isAdmin">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz edycji kontaktu</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista kontaktów' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="name">
                      Nazwa kontaktu
                      <em class="text-danger">*</em>
                    </label>
                    <input id="name" v-model.trim="form.name" @input="$v.form.name.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.name.$error, 'is-valid': !$v.form.name.$invalid && !$v.form.name.$error }" type="text" name="name" placeholder="Wpisz nazwę kontaktu" value required />
                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="email">
                      Adres email kontaktu
                      <em class="text-danger">*</em>
                    </label>
                    <input id="email" v-model.trim="form.email" @input="$v.form.email.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.email.$error, 'is-valid': !$v.form.email.$invalid && !$v.form.email.$error }" type="email" name="email" placeholder="Wpisz email kontaktu" value required />
                    <div v-if="$v.form.email.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.email.$error && !$v.form.email.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.email.$error && !$v.form.email.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.email.$error && !$v.form.email.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.email.$error && !$v.form.email.email">Niepoprawny adres email!</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Aktywny:</span>
                      <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>

                  <button class="btn btn-dark" v-on:click="updateContact" :disabled="$v.form.$invalid" type="submit">Aktualizuj kontakt</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
